<template>
  <div>
    <CRow>
      <CCol>
  <CCard>
   
  <CCardBody>
    <!-- <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewGroup">New Group </button>
    </div> -->

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
        :responsive="false">
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     <template #typePosition="{item}">
        <td class='font-weight-bold'
          v-if="item.typePosition"
        >{{ item.typePosition }}</td>
        <td v-else>--</td>
      </template>
     <template #mechanicName="{item}">
        <td class='font-weight-bold'
          v-if="item.mechanicName"
        >{{ item.mechanicName }}</td>
        <td v-else>--</td>
      </template>
      <template #driverName="{item}">
        <td class='font-weight-bold'
          v-if="item.driverName"
        >{{ item.driverName }}</td>
        <td v-else>--</td>
      </template>
      <!-- <template #driverName="{item}">
        <td class='font-weight-bold'
          v-if="item.driverName"
        >{{ item.driverName }}</td>
        <td v-else>--</td>
      </template> -->
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'TyreChangeForm', params: {tyreFormId: item.tyreFormId, mode: 'view'}}">View </CDropdownItem>
           <CDropdownItem  @click="navToExportPayments(item.tyreFormId)" > Export</CDropdownItem>
                </CDropdown>

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>

 </CCard>
 
      </CCol>
      </CRow>
 </div>
</template>

<script>
const fields = [
  'index',
  { key: "mechanicName", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "driverName", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "typePosition", _classes: 'font-weight-bold', _style: "min-width:200px" },
 // { key: "driverNricNo", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "createdTime",label: "Created Date & Time", _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "TyreChangeForms",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    navToExportPayments(tyreFormId){
        
    var filename = "TyreChangeForm.xls";
       this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/send/tyreForm/pdf/" + tyreFormId
      )
        .then((response) => {
          if (response.status === 200) {
            // console.log("headers", response.headers);
            // filename = response.headers
            //   .get("Content-Disposition")
            //   .split("filename=")[1];
            // console.log(filename);
            // console.log(response.blob());
            return response.blob();
          } else {
            // alert("Access");
            return;
          }
        })
        .then((blob) => {
          console.log("blob values:::",blob)
          var downloadUrl = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove(); 
        });

    },
    getForms() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/tyreChangeForms")
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    // navToExportPayments(tyreFormId){
        
    // var filename = "TyreFormReport.xls";
    //   fetch(
    //     process.env.VUE_APP_API_HOST +
    //       "/emapp/web/tyre/changeForm/export/" + tyreFormId
    //   )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         console.log("headers", response.headers);
    //         // filename = response.headers
    //         //   .get("Content-Disposition")
    //         //   .split("filename=")[1];
    //         // console.log(filename);

    //         return response.blob();
    //       } else {
    //         return;
    //       }
    //     })
    //     .then((blob) => {
    //       var downloadUrl = window.URL.createObjectURL(blob);
    //       var a = document.createElement("a");
    //       a.href = downloadUrl;
    //       a.download = filename;
    //       document.body.appendChild(a);
    //       a.click();
    //       a.remove(); 
    //     });

    // },
   
  },
  mounted() {
    this.getForms();
  },
};
</script>